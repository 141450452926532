/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DataTypeEnum from '../model/DataTypeEnum';
import DataUploadStatus from '../model/DataUploadStatus';
import DatalakeIn from '../model/DatalakeIn';
import DatalakeOut from '../model/DatalakeOut';
import DatalakePermissionnerIn from '../model/DatalakePermissionnerIn';
import DatalakePermissionnerOut from '../model/DatalakePermissionnerOut';
import DatalakePermissionnerPermissionsPatchIn from '../model/DatalakePermissionnerPermissionsPatchIn';
import ExtendedDataOut from '../model/ExtendedDataOut';
import ExtendedDatalakeOut from '../model/ExtendedDatalakeOut';
import ExtendedDatalakePermissionnerOut from '../model/ExtendedDatalakePermissionnerOut';
import GeneratePresignedUrlIn from '../model/GeneratePresignedUrlIn';
import GeneratePresignedUrlOut from '../model/GeneratePresignedUrlOut';
import OptionalDatalakeIn from '../model/OptionalDatalakeIn';
import PagedDataOut from '../model/PagedDataOut';
import PagedExtendedDataOut from '../model/PagedExtendedDataOut';
import PagedTagOut from '../model/PagedTagOut';
import PagedUUID from '../model/PagedUUID';
import Payload from '../model/Payload';
import TagBulkActionIn from '../model/TagBulkActionIn';
import TagIn from '../model/TagIn';
import TagOut from '../model/TagOut';
import UploadDataIn from '../model/UploadDataIn';

/**
* Datalake service.
* @module api/DatalakeApi
* @version 1.0.0
*/
export default class DatalakeApi {

    /**
    * Constructs a new DatalakeApi. 
    * @alias module:api/DatalakeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakePermissionnerIn} datalakePermissionnerIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakePermissionnerOut} and HTTP response
     */
    addDatalakePermissionnerWithHttpInfo(id, datalakePermissionnerIn) {
      let postBody = datalakePermissionnerIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling addDatalakePermissionner");
      }
      // verify the required parameter 'datalakePermissionnerIn' is set
      if (datalakePermissionnerIn === undefined || datalakePermissionnerIn === null) {
        throw new Error("Missing the required parameter 'datalakePermissionnerIn' when calling addDatalakePermissionner");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DatalakePermissionnerOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/permissionners', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakePermissionnerIn} datalakePermissionnerIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakePermissionnerOut}
     */
    addDatalakePermissionner(id, datalakePermissionnerIn) {
      return this.addDatalakePermissionnerWithHttpInfo(id, datalakePermissionnerIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    bulkAttachTagsOnDatalakeScopeWithHttpInfo(id, tagBulkActionIn) {
      let postBody = tagBulkActionIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkAttachTagsOnDatalakeScope");
      }
      // verify the required parameter 'tagBulkActionIn' is set
      if (tagBulkActionIn === undefined || tagBulkActionIn === null) {
        throw new Error("Missing the required parameter 'tagBulkActionIn' when calling bulkAttachTagsOnDatalakeScope");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags/attach', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    bulkAttachTagsOnDatalakeScope(id, tagBulkActionIn) {
      return this.bulkAttachTagsOnDatalakeScopeWithHttpInfo(id, tagBulkActionIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Bulk delete Data
     * @param {String} id 
     * @param {module:model/Payload} payload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    bulkDeleteDatalakeDataWithHttpInfo(id, payload) {
      let postBody = payload;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkDeleteDatalakeData");
      }
      // verify the required parameter 'payload' is set
      if (payload === undefined || payload === null) {
        throw new Error("Missing the required parameter 'payload' when calling bulkDeleteDatalakeData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Bulk delete Data
     * @param {String} id 
     * @param {module:model/Payload} payload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    bulkDeleteDatalakeData(id, payload) {
      return this.bulkDeleteDatalakeDataWithHttpInfo(id, payload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    bulkDetachTagsOnDatalakeScopeWithHttpInfo(id, tagBulkActionIn) {
      let postBody = tagBulkActionIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling bulkDetachTagsOnDatalakeScope");
      }
      // verify the required parameter 'tagBulkActionIn' is set
      if (tagBulkActionIn === undefined || tagBulkActionIn === null) {
        throw new Error("Missing the required parameter 'tagBulkActionIn' when calling bulkDetachTagsOnDatalakeScope");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags/detach', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/TagBulkActionIn} tagBulkActionIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    bulkDetachTagsOnDatalakeScope(id, tagBulkActionIn) {
      return this.bulkDetachTagsOnDatalakeScopeWithHttpInfo(id, tagBulkActionIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/UploadDataIn} uploadDataIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDataOut} and HTTP response
     */
    createDataWithHttpInfo(id, uploadDataIn) {
      let postBody = uploadDataIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createData");
      }
      // verify the required parameter 'uploadDataIn' is set
      if (uploadDataIn === undefined || uploadDataIn === null) {
        throw new Error("Missing the required parameter 'uploadDataIn' when calling createData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedDataOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/UploadDataIn} uploadDataIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDataOut}
     */
    createData(id, uploadDataIn) {
      return this.createDataWithHttpInfo(id, uploadDataIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Tag(s)
     * @param {String} id 
     * @param {module:model/TagIn} tagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    createDatalakeTagsWithHttpInfo(id, tagIn) {
      let postBody = tagIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createDatalakeTags");
      }
      // verify the required parameter 'tagIn' is set
      if (tagIn === undefined || tagIn === null) {
        throw new Error("Missing the required parameter 'tagIn' when calling createDatalakeTags");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create Tag(s)
     * @param {String} id 
     * @param {module:model/TagIn} tagIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    createDatalakeTags(id, tagIn) {
      return this.createDatalakeTagsWithHttpInfo(id, tagIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete Datalake
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteDatalakeWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/datalake/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete Datalake
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteDatalake(id) {
      return this.deleteDatalakeWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete DatalakePermissionner
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteDatalakepermissionnerWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteDatalakepermissionner");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/datalake/permissioner/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Delete DatalakePermissionner
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteDatalakepermissionner(id) {
      return this.deleteDatalakepermissionnerWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Data
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [id2] 
     * @param {String} [objectName] 
     * @param {String} [filename] 
     * @param {module:model/DataTypeEnum} [type] 
     * @param {String} [contentType] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDataOut} and HTTP response
     */
    findDatalakeDataWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findDatalakeData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'id': opts['id2'],
        'object_name': opts['objectName'],
        'filename': opts['filename'],
        'type': opts['type'],
        'content_type': opts['contentType']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDataOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Data
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.id2 
     * @param {String} opts.objectName 
     * @param {String} opts.filename 
     * @param {module:model/DataTypeEnum} opts.type 
     * @param {String} opts.contentType 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDataOut}
     */
    findDatalakeData(id, opts) {
      return this.findDatalakeDataWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Find Tag
     * @param {String} id 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagOut} and HTTP response
     */
    findDatalakeTagWithHttpInfo(id, name) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findDatalakeTag");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling findDatalakeTag");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': name
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = TagOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags/find', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Find Tag
     * @param {String} id 
     * @param {String} name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagOut}
     */
    findDatalakeTag(id, name) {
      return this.findDatalakeTagWithHttpInfo(id, name)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/GeneratePresignedUrlIn} generatePresignedUrlIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GeneratePresignedUrlOut} and HTTP response
     */
    generateDataPresignedUrlWithHttpInfo(id, generatePresignedUrlIn) {
      let postBody = generatePresignedUrlIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling generateDataPresignedUrl");
      }
      // verify the required parameter 'generatePresignedUrlIn' is set
      if (generatePresignedUrlIn === undefined || generatePresignedUrlIn === null) {
        throw new Error("Missing the required parameter 'generatePresignedUrlIn' when calling generateDataPresignedUrl");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = GeneratePresignedUrlOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/data/presigned', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/GeneratePresignedUrlIn} generatePresignedUrlIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GeneratePresignedUrlOut}
     */
    generateDataPresignedUrl(id, generatePresignedUrlIn) {
      return this.generateDataPresignedUrlWithHttpInfo(id, generatePresignedUrlIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Datalake
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatalakeOut} and HTTP response
     */
    getDatalakeWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDatalakeOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Datalake
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatalakeOut}
     */
    getDatalake(id) {
      return this.getDatalakeWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get DatalakePermissionner
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatalakePermissionnerOut} and HTTP response
     */
    getDatalakepermissionnerWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getDatalakepermissionner");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDatalakePermissionnerOut;
      return this.apiClient.callApi(
        '/api/datalake/permissioner/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get DatalakePermissionner
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatalakePermissionnerOut}
     */
    getDatalakepermissionner(id) {
      return this.getDatalakepermissionnerWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatalakePermissionnerOut} and HTTP response
     */
    getMyDatalakePermissionnerWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getMyDatalakePermissionner");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ExtendedDatalakePermissionnerOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/permissionner', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatalakePermissionnerOut}
     */
    getMyDatalakePermissionner(id) {
      return this.getMyDatalakePermissionnerWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Introspect Data
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
     */
    introspectDatalakeDataWithHttpInfo(id) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling introspectDatalakeData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/datalake/{id}/data/introspect', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Introspect Data
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
     */
    introspectDatalakeData(id) {
      return this.introspectDatalakeDataWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datas
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [tagIds] 
     * @param {String} [q] 
     * @param {Array.<String>} [ids] 
     * @param {Array.<String>} [filenames] 
     * @param {Array.<String>} [objectNames] 
     * @param {module:model/DataTypeEnum} [type] 
     * @param {String} [contentType] 
     * @param {Date} [createdAtLte] 
     * @param {Date} [createdAtGte] 
     * @param {Array.<String>} [filenameStartswith] 
     * @param {module:model/DataUploadStatus} [uploadStatus] 
     * @param {Array.<module:model/DataTypeEnum>} [types] 
     * @param {Array.<module:model/DataUploadStatus>} [uploadStatuses] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedDataOut} and HTTP response
     */
    listDatalakeDatasWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeDatas");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'tag_ids': this.apiClient.buildCollectionParam(opts['tagIds'], 'multi'),
        'q': opts['q'],
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'filenames': this.apiClient.buildCollectionParam(opts['filenames'], 'multi'),
        'object_names': this.apiClient.buildCollectionParam(opts['objectNames'], 'multi'),
        'type': opts['type'],
        'content_type': opts['contentType'],
        'created_at_lte': opts['createdAtLte'],
        'created_at_gte': opts['createdAtGte'],
        'filename_startswith': this.apiClient.buildCollectionParam(opts['filenameStartswith'], 'multi'),
        'upload_status': opts['uploadStatus'],
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'upload_statuses': this.apiClient.buildCollectionParam(opts['uploadStatuses'], 'multi'),
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedDataOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datas
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tagIds 
     * @param {String} opts.q 
     * @param {Array.<String>} opts.ids 
     * @param {Array.<String>} opts.filenames 
     * @param {Array.<String>} opts.objectNames 
     * @param {module:model/DataTypeEnum} opts.type 
     * @param {String} opts.contentType 
     * @param {Date} opts.createdAtLte 
     * @param {Date} opts.createdAtGte 
     * @param {Array.<String>} opts.filenameStartswith 
     * @param {module:model/DataUploadStatus} opts.uploadStatus 
     * @param {Array.<module:model/DataTypeEnum>} opts.types 
     * @param {Array.<module:model/DataUploadStatus>} opts.uploadStatuses 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedDataOut}
     */
    listDatalakeDatas(id, opts) {
      return this.listDatalakeDatasWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datas [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [tagIds] 
     * @param {String} [q] 
     * @param {Array.<String>} [ids] 
     * @param {Array.<String>} [filenames] 
     * @param {Array.<String>} [objectNames] 
     * @param {module:model/DataTypeEnum} [type] 
     * @param {String} [contentType] 
     * @param {Date} [createdAtLte] 
     * @param {Date} [createdAtGte] 
     * @param {Array.<String>} [filenameStartswith] 
     * @param {module:model/DataUploadStatus} [uploadStatus] 
     * @param {Array.<module:model/DataTypeEnum>} [types] 
     * @param {Array.<module:model/DataUploadStatus>} [uploadStatuses] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedExtendedDataOut} and HTTP response
     */
    listDatalakeDatasExtendedWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeDatasExtended");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'tag_ids': this.apiClient.buildCollectionParam(opts['tagIds'], 'multi'),
        'q': opts['q'],
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'filenames': this.apiClient.buildCollectionParam(opts['filenames'], 'multi'),
        'object_names': this.apiClient.buildCollectionParam(opts['objectNames'], 'multi'),
        'type': opts['type'],
        'content_type': opts['contentType'],
        'created_at_lte': opts['createdAtLte'],
        'created_at_gte': opts['createdAtGte'],
        'filename_startswith': this.apiClient.buildCollectionParam(opts['filenameStartswith'], 'multi'),
        'upload_status': opts['uploadStatus'],
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'upload_statuses': this.apiClient.buildCollectionParam(opts['uploadStatuses'], 'multi'),
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedExtendedDataOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas/extended', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datas [extended]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tagIds 
     * @param {String} opts.q 
     * @param {Array.<String>} opts.ids 
     * @param {Array.<String>} opts.filenames 
     * @param {Array.<String>} opts.objectNames 
     * @param {module:model/DataTypeEnum} opts.type 
     * @param {String} opts.contentType 
     * @param {Date} opts.createdAtLte 
     * @param {Date} opts.createdAtGte 
     * @param {Array.<String>} opts.filenameStartswith 
     * @param {module:model/DataUploadStatus} opts.uploadStatus 
     * @param {Array.<module:model/DataTypeEnum>} opts.types 
     * @param {Array.<module:model/DataUploadStatus>} opts.uploadStatuses 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedExtendedDataOut}
     */
    listDatalakeDatasExtended(id, opts) {
      return this.listDatalakeDatasExtendedWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datas [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [tagIds] 
     * @param {String} [q] 
     * @param {Array.<String>} [ids] 
     * @param {Array.<String>} [filenames] 
     * @param {Array.<String>} [objectNames] 
     * @param {module:model/DataTypeEnum} [type] 
     * @param {String} [contentType] 
     * @param {Date} [createdAtLte] 
     * @param {Date} [createdAtGte] 
     * @param {Array.<String>} [filenameStartswith] 
     * @param {module:model/DataUploadStatus} [uploadStatus] 
     * @param {Array.<module:model/DataTypeEnum>} [types] 
     * @param {Array.<module:model/DataUploadStatus>} [uploadStatuses] 
     * @param {Array.<String>} [orderBy] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    listDatalakeDatasIdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeDatasIds");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'tag_ids': this.apiClient.buildCollectionParam(opts['tagIds'], 'multi'),
        'q': opts['q'],
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'filenames': this.apiClient.buildCollectionParam(opts['filenames'], 'multi'),
        'object_names': this.apiClient.buildCollectionParam(opts['objectNames'], 'multi'),
        'type': opts['type'],
        'content_type': opts['contentType'],
        'created_at_lte': opts['createdAtLte'],
        'created_at_gte': opts['createdAtGte'],
        'filename_startswith': this.apiClient.buildCollectionParam(opts['filenameStartswith'], 'multi'),
        'upload_status': opts['uploadStatus'],
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'upload_statuses': this.apiClient.buildCollectionParam(opts['uploadStatuses'], 'multi'),
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas/ids', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datas [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tagIds 
     * @param {String} opts.q 
     * @param {Array.<String>} opts.ids 
     * @param {Array.<String>} opts.filenames 
     * @param {Array.<String>} opts.objectNames 
     * @param {module:model/DataTypeEnum} opts.type 
     * @param {String} opts.contentType 
     * @param {Date} opts.createdAtLte 
     * @param {Date} opts.createdAtGte 
     * @param {Array.<String>} opts.filenameStartswith 
     * @param {module:model/DataUploadStatus} opts.uploadStatus 
     * @param {Array.<module:model/DataTypeEnum>} opts.types 
     * @param {Array.<module:model/DataUploadStatus>} opts.uploadStatuses 
     * @param {Array.<String>} opts.orderBy 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    listDatalakeDatasIds(id, opts) {
      return this.listDatalakeDatasIdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Datas [paginated ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} [tagIds] 
     * @param {String} [q] 
     * @param {Array.<String>} [ids] 
     * @param {Array.<String>} [filenames] 
     * @param {Array.<String>} [objectNames] 
     * @param {module:model/DataTypeEnum} [type] 
     * @param {String} [contentType] 
     * @param {Date} [createdAtLte] 
     * @param {Date} [createdAtGte] 
     * @param {Array.<String>} [filenameStartswith] 
     * @param {module:model/DataUploadStatus} [uploadStatus] 
     * @param {Array.<module:model/DataTypeEnum>} [types] 
     * @param {Array.<module:model/DataUploadStatus>} [uploadStatuses] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedUUID} and HTTP response
     */
    listDatalakeDatasPaginatedIdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeDatasPaginatedIds");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'tag_ids': this.apiClient.buildCollectionParam(opts['tagIds'], 'multi'),
        'q': opts['q'],
        'ids': this.apiClient.buildCollectionParam(opts['ids'], 'multi'),
        'filenames': this.apiClient.buildCollectionParam(opts['filenames'], 'multi'),
        'object_names': this.apiClient.buildCollectionParam(opts['objectNames'], 'multi'),
        'type': opts['type'],
        'content_type': opts['contentType'],
        'created_at_lte': opts['createdAtLte'],
        'created_at_gte': opts['createdAtGte'],
        'filename_startswith': this.apiClient.buildCollectionParam(opts['filenameStartswith'], 'multi'),
        'upload_status': opts['uploadStatus'],
        'types': this.apiClient.buildCollectionParam(opts['types'], 'multi'),
        'upload_statuses': this.apiClient.buildCollectionParam(opts['uploadStatuses'], 'multi'),
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedUUID;
      return this.apiClient.callApi(
        '/api/datalake/{id}/datas/ids/paginated', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Datas [paginated ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tagIds 
     * @param {String} opts.q 
     * @param {Array.<String>} opts.ids 
     * @param {Array.<String>} opts.filenames 
     * @param {Array.<String>} opts.objectNames 
     * @param {module:model/DataTypeEnum} opts.type 
     * @param {String} opts.contentType 
     * @param {Date} opts.createdAtLte 
     * @param {Date} opts.createdAtGte 
     * @param {Array.<String>} opts.filenameStartswith 
     * @param {module:model/DataUploadStatus} opts.uploadStatus 
     * @param {Array.<module:model/DataTypeEnum>} opts.types 
     * @param {Array.<module:model/DataUploadStatus>} opts.uploadStatuses 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedUUID}
     */
    listDatalakeDatasPaginatedIds(id, opts) {
      return this.listDatalakeDatasPaginatedIdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Tags
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {String} [parentId] 
     * @param {Boolean} [isRoot] 
     * @param {Number} [limit] Number of objects to retrieve by page
     * @param {Number} [offset] Offset of page to use
     * @param {Array.<String>} [orderBy] List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PagedTagOut} and HTTP response
     */
    listDatalakeTagsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeTags");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'parent_id': opts['parentId'],
        'is_root': opts['isRoot'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PagedTagOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Tags
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {String} opts.parentId 
     * @param {Boolean} opts.isRoot 
     * @param {Number} opts.limit Number of objects to retrieve by page
     * @param {Number} opts.offset Offset of page to use
     * @param {Array.<String>} opts.orderBy List of fields to sort by
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PagedTagOut}
     */
    listDatalakeTags(id, opts) {
      return this.listDatalakeTagsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Tags [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @param {String} [parentId] 
     * @param {Boolean} [isRoot] 
     * @param {Array.<String>} [orderBy] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<String>} and HTTP response
     */
    listDatalakeTagsIdsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listDatalakeTagsIds");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name'],
        'parent_id': opts['parentId'],
        'is_root': opts['isRoot'],
        'order_by': this.apiClient.buildCollectionParam(opts['orderBy'], 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ['String'];
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags/ids', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Tags [ids]
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {String} opts.parentId 
     * @param {Boolean} opts.isRoot 
     * @param {Array.<String>} opts.orderBy 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<String>}
     */
    listDatalakeTagsIds(id, opts) {
      return this.listDatalakeTagsIdsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {Array.<String>} requestBody 
     * @param {Object} opts Optional parameters
     * @param {String} [name] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/TagOut>} and HTTP response
     */
    listTagsAttachedToDataWithHttpInfo(id, requestBody, opts) {
      opts = opts || {};
      let postBody = requestBody;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listTagsAttachedToData");
      }
      // verify the required parameter 'requestBody' is set
      if (requestBody === undefined || requestBody === null) {
        throw new Error("Missing the required parameter 'requestBody' when calling listTagsAttachedToData");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [TagOut];
      return this.apiClient.callApi(
        '/api/datalake/{id}/tags/data', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {Array.<String>} requestBody 
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/TagOut>}
     */
    listTagsAttachedToData(id, requestBody, opts) {
      return this.listTagsAttachedToDataWithHttpInfo(id, requestBody, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Patch Datalake
     * @param {String} id 
     * @param {module:model/OptionalDatalakeIn} optionalDatalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakeOut} and HTTP response
     */
    patchDatalakeWithHttpInfo(id, optionalDatalakeIn) {
      let postBody = optionalDatalakeIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling patchDatalake");
      }
      // verify the required parameter 'optionalDatalakeIn' is set
      if (optionalDatalakeIn === undefined || optionalDatalakeIn === null) {
        throw new Error("Missing the required parameter 'optionalDatalakeIn' when calling patchDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DatalakeOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Patch Datalake
     * @param {String} id 
     * @param {module:model/OptionalDatalakeIn} optionalDatalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakeOut}
     */
    patchDatalake(id, optionalDatalakeIn) {
      return this.patchDatalakeWithHttpInfo(id, optionalDatalakeIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update Datalake
     * @param {String} id 
     * @param {module:model/DatalakeIn} datalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DatalakeOut} and HTTP response
     */
    updateDatalakeWithHttpInfo(id, datalakeIn) {
      let postBody = datalakeIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateDatalake");
      }
      // verify the required parameter 'datalakeIn' is set
      if (datalakeIn === undefined || datalakeIn === null) {
        throw new Error("Missing the required parameter 'datalakeIn' when calling updateDatalake");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DatalakeOut;
      return this.apiClient.callApi(
        '/api/datalake/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update Datalake
     * @param {String} id 
     * @param {module:model/DatalakeIn} datalakeIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DatalakeOut}
     */
    updateDatalake(id, datalakeIn) {
      return this.updateDatalakeWithHttpInfo(id, datalakeIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakePermissionnerPermissionsPatchIn} datalakePermissionnerPermissionsPatchIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ExtendedDatalakePermissionnerOut} and HTTP response
     */
    updateDatalakePermissionnerWithHttpInfo(id, datalakePermissionnerPermissionsPatchIn) {
      let postBody = datalakePermissionnerPermissionsPatchIn;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateDatalakePermissionner");
      }
      // verify the required parameter 'datalakePermissionnerPermissionsPatchIn' is set
      if (datalakePermissionnerPermissionsPatchIn === undefined || datalakePermissionnerPermissionsPatchIn === null) {
        throw new Error("Missing the required parameter 'datalakePermissionnerPermissionsPatchIn' when calling updateDatalakePermissionner");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['TokenAuthentication', 'SessionAuthentication'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ExtendedDatalakePermissionnerOut;
      return this.apiClient.callApi(
        '/api/datalake/permissioner/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     *  
     * @param {String} id 
     * @param {module:model/DatalakePermissionnerPermissionsPatchIn} datalakePermissionnerPermissionsPatchIn 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ExtendedDatalakePermissionnerOut}
     */
    updateDatalakePermissionner(id, datalakePermissionnerPermissionsPatchIn) {
      return this.updateDatalakePermissionnerWithHttpInfo(id, datalakePermissionnerPermissionsPatchIn)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
