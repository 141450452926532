import PxlDatalakeApi from "openapi-client/src/api/DatalakeApi.js";
import { chunkAndParallelizeWrapper } from "@/js/utils/parallelize";
import { uploadFile } from "@/js/utils/upload";
import DataTypeEnum from "@/js/enums/DataTypeEnum.js";
import client from "@/js/api/client.js";

function getImageSize(file) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const { height, width } = img;
      URL.revokeObjectURL(img.src);
      resolve({ height, width });
    };
    img.onerror = () => resolve(null);
  });
}

function getDataMeta(type, data) {
  switch (type) {
    case DataTypeEnum.IMAGE:
      return getImageSize(data);
    case DataTypeEnum.VIDEO:
    default:
      return Promise.resolve(null);
  }
}

function getDatumType(datum) {
  if (datum.type.startsWith("image/")) {
    return DataTypeEnum.IMAGE;
  } else if (datum.type.startsWith("video/")) {
    return DataTypeEnum.VIDEO;
  } else {
    return null;
  }
}
class DatalakeApi extends PxlDatalakeApi {
  async chunkListTagsAttachedToData(datalakeId, dataIds, queryParameters, options) {
    const fetcher = (chunkedDataIds) => this.listTagsAttachedToData(datalakeId, chunkedDataIds, queryParameters);
    const tags = await chunkAndParallelizeWrapper(
      fetcher,
      dataIds,
      Object.assign({ chunkSize: 1000, requestCount: 1 }, options),
    );
    return tags.filter(({ id: tagId }, index) => tags.findIndex((tag) => tag.id === tagId) === index);
  }

  chunkBulkDeleteDatalakeData(datalakeId, dataIds, options) {
    const fetcher = (chunkedDataIds) => this.bulkDeleteDatalakeData(datalakeId, chunkedDataIds);
    return chunkAndParallelizeWrapper(fetcher, dataIds, Object.assign({ chunkSize: 1000, requestCount: 1 }, options));
  }

  uploadData(datalakeId, data, tagIds = [], { onProgress = () => {}, signal } = {}) {
    const fetcher = async (chunkedData) => {
      return Promise.all(
        chunkedData.map((datum) => this.uploadDatum(datalakeId, datum, tagIds, { onProgress, signal })),
      );
    };
    return chunkAndParallelizeWrapper(fetcher, data, Object.assign({ chunkSize: 30, requestCount: 1 }, { signal }));
  }

  async uploadDatum(datalakeId, datum, tagIds = [], { onProgress = () => {}, signal } = {}) {
    // Ask Hinokuni for a presigned url to post on
    const uploadData = await this.generateDataPresignedUrl(datalakeId, {
      filename: datum.name,
      content_type: datum.type,
    });

    // Upload datum on S3 storage
    await uploadFile(datum, uploadData, { signal });

    // Guess datum type from content type
    const type = getDatumType(datum);

    const dataPayload = {
      object_name: uploadData.object_name,
      filename: datum.name,
      content_type: datum.type,
      // If we could not compute data type, suppose it is an Image and Konpaku will do the job
      type: type || DataTypeEnum.IMAGE,
      tags: tagIds,
    };

    // Try opening image to compute width and height in client side
    const meta = await getDataMeta(type, datum);
    if (meta !== null) dataPayload.meta = meta;

    // Call Hinokuni with newly created data
    await this.createData(datalakeId, dataPayload);
    onProgress(1);
  }

  listDatalakeDatasExtended(datalakeId, query) {
    const queryParameters = { ...query };
    queryParameters.types = ["VIDEO", "IMAGE"];
    queryParameters.uploadStatuses = ["PENDING", "COMPUTING", "ERROR", "DONE"];
    return super.listDatalakeDatasExtended(datalakeId, queryParameters);
  }

  listDatalakeDatasIds(datalakeId, query) {
    const queryParameters = { ...query };
    queryParameters.types = ["VIDEO", "IMAGE"];
    queryParameters.uploadStatuses = ["PENDING", "COMPUTING", "ERROR", "DONE"];
    return super.listDatalakeDatasIds(datalakeId, queryParameters);
  }

  listDatalakeDatas(datalakeId, query) {
    const queryParameters = { ...query };
    queryParameters.types = ["VIDEO", "IMAGE"];
    queryParameters.uploadStatuses = ["PENDING", "COMPUTING", "ERROR", "DONE"];
    return super.listDatalakeDatas(datalakeId, queryParameters);
  }
}

export default new DatalakeApi(client);
