/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class DatalakePermissionnerPermission.
* @enum {}
* @readonly
*/
export default class DatalakePermissionnerPermission {
    
        /**
         * value: "add_datalakepermissionner"
         * @const
         */
        "add_datalakepermissionner" = "add_datalakepermissionner";

    
        /**
         * value: "change_datalakepermissionner"
         * @const
         */
        "change_datalakepermissionner" = "change_datalakepermissionner";

    
        /**
         * value: "delete_datalakepermissionner"
         * @const
         */
        "delete_datalakepermissionner" = "delete_datalakepermissionner";

    
        /**
         * value: "view_datalakepermissionner"
         * @const
         */
        "view_datalakepermissionner" = "view_datalakepermissionner";

    
        /**
         * value: "add_data"
         * @const
         */
        "add_data" = "add_data";

    
        /**
         * value: "change_data"
         * @const
         */
        "change_data" = "change_data";

    
        /**
         * value: "delete_data"
         * @const
         */
        "delete_data" = "delete_data";

    
        /**
         * value: "view_data"
         * @const
         */
        "view_data" = "view_data";

    
        /**
         * value: "add_datalake"
         * @const
         */
        "add_datalake" = "add_datalake";

    
        /**
         * value: "change_datalake"
         * @const
         */
        "change_datalake" = "change_datalake";

    
        /**
         * value: "delete_datalake"
         * @const
         */
        "delete_datalake" = "delete_datalake";

    
        /**
         * value: "view_datalake"
         * @const
         */
        "view_datalake" = "view_datalake";

    
        /**
         * value: "add_tag"
         * @const
         */
        "add_tag" = "add_tag";

    
        /**
         * value: "view_tag"
         * @const
         */
        "view_tag" = "view_tag";

    

    /**
    * Returns a <code>DatalakePermissionnerPermission</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/DatalakePermissionnerPermission} The enum <code>DatalakePermissionnerPermission</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

