/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DataTypeEnum from './DataTypeEnum';
import MetaOut from './MetaOut';
import MetadataIn from './MetadataIn';

/**
 * The UploadDataIn model module.
 * @module model/UploadDataIn
 * @version 1.0.0
 */
class UploadDataIn {
    /**
     * Constructs a new <code>UploadDataIn</code>.
     * @alias module:model/UploadDataIn
     * @param type {module:model/DataTypeEnum} 
     * @param objectName {String} 
     * @param filename {String} 
     * @param contentType {String} 
     */
    constructor(type, objectName, filename, contentType) { 
        
        UploadDataIn.initialize(this, type, objectName, filename, contentType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, type, objectName, filename, contentType) { 
        obj['type'] = type;
        obj['object_name'] = objectName;
        obj['filename'] = filename;
        obj['content_type'] = contentType;
    }

    /**
     * Constructs a <code>UploadDataIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UploadDataIn} obj Optional instance to populate.
     * @return {module:model/UploadDataIn} The populated <code>UploadDataIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UploadDataIn();

            if (data.hasOwnProperty('type')) {
                obj['type'] = DataTypeEnum.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = MetaOut.constructFromObject(data['meta']);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], ['String']);
            }
            if (data.hasOwnProperty('data_source_id')) {
                obj['data_source_id'] = ApiClient.convertToType(data['data_source_id'], 'String');
            }
            if (data.hasOwnProperty('file_size')) {
                obj['file_size'] = ApiClient.convertToType(data['file_size'], 'Number');
            }
            if (data.hasOwnProperty('object_name')) {
                obj['object_name'] = ApiClient.convertToType(data['object_name'], 'String');
            }
            if (data.hasOwnProperty('filename')) {
                obj['filename'] = ApiClient.convertToType(data['filename'], 'String');
            }
            if (data.hasOwnProperty('content_type')) {
                obj['content_type'] = ApiClient.convertToType(data['content_type'], 'String');
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = MetadataIn.constructFromObject(data['metadata']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UploadDataIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UploadDataIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UploadDataIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `meta`
        if (data['meta']) { // data not null
          MetaOut.validateJSON(data['meta']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['tags'])) {
            throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
        }
        // ensure the json data is a string
        if (data['data_source_id'] && !(typeof data['data_source_id'] === 'string' || data['data_source_id'] instanceof String)) {
            throw new Error("Expected the field `data_source_id` to be a primitive type in the JSON string but got " + data['data_source_id']);
        }
        // ensure the json data is a string
        if (data['object_name'] && !(typeof data['object_name'] === 'string' || data['object_name'] instanceof String)) {
            throw new Error("Expected the field `object_name` to be a primitive type in the JSON string but got " + data['object_name']);
        }
        // ensure the json data is a string
        if (data['filename'] && !(typeof data['filename'] === 'string' || data['filename'] instanceof String)) {
            throw new Error("Expected the field `filename` to be a primitive type in the JSON string but got " + data['filename']);
        }
        // ensure the json data is a string
        if (data['content_type'] && !(typeof data['content_type'] === 'string' || data['content_type'] instanceof String)) {
            throw new Error("Expected the field `content_type` to be a primitive type in the JSON string but got " + data['content_type']);
        }
        // validate the optional field `metadata`
        if (data['metadata']) { // data not null
          MetadataIn.validateJSON(data['metadata']);
        }

        return true;
    }


}

UploadDataIn.RequiredProperties = ["type", "object_name", "filename", "content_type"];

/**
 * @member {module:model/DataTypeEnum} type
 */
UploadDataIn.prototype['type'] = undefined;

/**
 * @member {module:model/MetaOut} meta
 */
UploadDataIn.prototype['meta'] = undefined;

/**
 * @member {Array.<String>} tags
 */
UploadDataIn.prototype['tags'] = undefined;

/**
 * @member {String} data_source_id
 */
UploadDataIn.prototype['data_source_id'] = undefined;

/**
 * @member {Number} file_size
 */
UploadDataIn.prototype['file_size'] = undefined;

/**
 * @member {String} object_name
 */
UploadDataIn.prototype['object_name'] = undefined;

/**
 * @member {String} filename
 */
UploadDataIn.prototype['filename'] = undefined;

/**
 * @member {String} content_type
 */
UploadDataIn.prototype['content_type'] = undefined;

/**
 * @member {module:model/MetadataIn} metadata
 */
UploadDataIn.prototype['metadata'] = undefined;






export default UploadDataIn;

