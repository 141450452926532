/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DatalakePermissionnerRole from './DatalakePermissionnerRole';

/**
 * The DatalakePermissionnerIn model module.
 * @module model/DatalakePermissionnerIn
 * @version 1.0.0
 */
class DatalakePermissionnerIn {
    /**
     * Constructs a new <code>DatalakePermissionnerIn</code>.
     * @alias module:model/DatalakePermissionnerIn
     * @param collaboratorId {String} 
     */
    constructor(collaboratorId) { 
        
        DatalakePermissionnerIn.initialize(this, collaboratorId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, collaboratorId) { 
        obj['collaborator_id'] = collaboratorId;
    }

    /**
     * Constructs a <code>DatalakePermissionnerIn</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DatalakePermissionnerIn} obj Optional instance to populate.
     * @return {module:model/DatalakePermissionnerIn} The populated <code>DatalakePermissionnerIn</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DatalakePermissionnerIn();

            if (data.hasOwnProperty('collaborator_id')) {
                obj['collaborator_id'] = ApiClient.convertToType(data['collaborator_id'], 'String');
            }
            if (data.hasOwnProperty('assigned_role')) {
                obj['assigned_role'] = DatalakePermissionnerRole.constructFromObject(data['assigned_role']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DatalakePermissionnerIn</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DatalakePermissionnerIn</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DatalakePermissionnerIn.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['collaborator_id'] && !(typeof data['collaborator_id'] === 'string' || data['collaborator_id'] instanceof String)) {
            throw new Error("Expected the field `collaborator_id` to be a primitive type in the JSON string but got " + data['collaborator_id']);
        }

        return true;
    }


}

DatalakePermissionnerIn.RequiredProperties = ["collaborator_id"];

/**
 * @member {String} collaborator_id
 */
DatalakePermissionnerIn.prototype['collaborator_id'] = undefined;

/**
 * @member {module:model/DatalakePermissionnerRole} assigned_role
 */
DatalakePermissionnerIn.prototype['assigned_role'] = undefined;






export default DatalakePermissionnerIn;

