/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollaboratorOut from './CollaboratorOut';
import DatalakePermissionnerPermissionOut from './DatalakePermissionnerPermissionOut';

/**
 * The ExtendedDatalakePermissionnerOut model module.
 * @module model/ExtendedDatalakePermissionnerOut
 * @version 1.0.0
 */
class ExtendedDatalakePermissionnerOut {
    /**
     * Constructs a new <code>ExtendedDatalakePermissionnerOut</code>.
     * @alias module:model/ExtendedDatalakePermissionnerOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param collaborator {module:model/CollaboratorOut} 
     * @param isActive {Boolean} 
     * @param permissions {Array.<module:model/DatalakePermissionnerPermissionOut>} 
     */
    constructor(createdAt, updatedAt, id, collaborator, isActive, permissions) { 
        
        ExtendedDatalakePermissionnerOut.initialize(this, createdAt, updatedAt, id, collaborator, isActive, permissions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, collaborator, isActive, permissions) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['collaborator'] = collaborator;
        obj['is_active'] = isActive;
        obj['permissions'] = permissions;
    }

    /**
     * Constructs a <code>ExtendedDatalakePermissionnerOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedDatalakePermissionnerOut} obj Optional instance to populate.
     * @return {module:model/ExtendedDatalakePermissionnerOut} The populated <code>ExtendedDatalakePermissionnerOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedDatalakePermissionnerOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('collaborator')) {
                obj['collaborator'] = CollaboratorOut.constructFromObject(data['collaborator']);
            }
            if (data.hasOwnProperty('is_active')) {
                obj['is_active'] = ApiClient.convertToType(data['is_active'], 'Boolean');
            }
            if (data.hasOwnProperty('permissions')) {
                obj['permissions'] = ApiClient.convertToType(data['permissions'], [DatalakePermissionnerPermissionOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedDatalakePermissionnerOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedDatalakePermissionnerOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedDatalakePermissionnerOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // validate the optional field `collaborator`
        if (data['collaborator']) { // data not null
          CollaboratorOut.validateJSON(data['collaborator']);
        }
        if (data['permissions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['permissions'])) {
                throw new Error("Expected the field `permissions` to be an array in the JSON data but got " + data['permissions']);
            }
            // validate the optional field `permissions` (array)
            for (const item of data['permissions']) {
                DatalakePermissionnerPermissionOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedDatalakePermissionnerOut.RequiredProperties = ["created_at", "updated_at", "id", "collaborator", "is_active", "permissions"];

/**
 * @member {Date} created_at
 */
ExtendedDatalakePermissionnerOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedDatalakePermissionnerOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedDatalakePermissionnerOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedDatalakePermissionnerOut.prototype['id'] = undefined;

/**
 * @member {module:model/CollaboratorOut} collaborator
 */
ExtendedDatalakePermissionnerOut.prototype['collaborator'] = undefined;

/**
 * @member {Boolean} is_active
 */
ExtendedDatalakePermissionnerOut.prototype['is_active'] = undefined;

/**
 * @member {Array.<module:model/DatalakePermissionnerPermissionOut>} permissions
 */
ExtendedDatalakePermissionnerOut.prototype['permissions'] = undefined;






export default ExtendedDatalakePermissionnerOut;

